import React from 'react';
import './HeaderContainer.css';

function HeaderContainer() {
  
  return (
    <header 
      className="header-container" 
      style={{
        backgroundImage: 'url("/bgHeader.png")', 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="left-block">
        <h1>Soluções Tecnológicas</h1>
        <h2>para seu negócio.</h2>
        <p>Nossa missão é transformar suas ideias em realidade para seu negócio ir mais longe.</p>
      </div>
      <div className="right-block">
        <img src="/casal.png" alt="Casal" />
      </div>
    </header>
  );
}

export default HeaderContainer;
