import React from 'react';
import { FaInstagram, FaEnvelope, FaWhatsapp } from 'react-icons/fa'; // Importa os ícones necessários
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-blocks">
        <div className="footer-block block-1">
          <a href='#' style={{textDecoration:'none'}}>
            <img src='/logo.png' alt="Intellisync Logo" className="footer-logo" />
          </a>
          <p>CNPJ 52.030.298/0001-86</p>
        </div>
        <div className="footer-block block-2">
          <h3>Menus</h3>
        </div>
        <div className="footer-block block-3">
          <h3>Redes Sociais</h3>
          <a href="https://www.instagram.com/intellisync.oficial" target="_blank" rel="noopener noreferrer" className="social-link">
            <FaInstagram className="social-icon" />
          </a>
        </div>
        <div className="footer-block block-4">
          <h3>Contato</h3>
          <div className="contact-item">
            <FaEnvelope className="social-icon" />
            <span>contato@intellisync.com.br</span>
          </div>
          <div className="contact-item">
            <FaWhatsapp className="social-icon" />
            <span>(31) 9 9103-8051</span>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Todos os direitos reservados Intellisync @2023</p>
      </div>
    </footer>
  );
}

export default Footer;
