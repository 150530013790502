import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css'; // Adicione o arquivo CSS para o estilo

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="logomarca">
        <a href='#' style={{textDecoration:'none'}}>
        <img src='/logo.png' alt="Intellisync Logo" />
        </a>
      </div>
      <div className={`navbarMenu ${isOpen ? 'active' : ''}`}>
        <a href="#home">Home</a>
        {/* <a href="#services">Sobre Nós</a> */}
        <a href="#servicos">Serviços</a>
        <a href="#contact">Contato</a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
}

export default Navbar;
