import React from "react";
import HeaderContainer from "./HeaderContainer";
import Footer from "../../component/footer/Footer";
import ContactForm from "./ContactForm";
import NossosServicosContainer from "./NossosServicosContainer";

// import { Container } from './styles';

function Home() {
  return (
    <>
      <HeaderContainer />
      <NossosServicosContainer/>
      <ContactForm/>
      <Footer />
    </>
  );
}

export default Home;
