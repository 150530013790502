import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import InputMask from 'react-input-mask';
import './Contact.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    assunto: '',
    mensagem: '',
  });

  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!formData.nome) newErrors.nome = 'Nome é obrigatório';
    if (!formData.email) {
      newErrors.email = 'Email é obrigatório';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email inválido';
    }
    if (!formData.telefone) {
      newErrors.telefone = 'Telefone é obrigatório';
    } else if (!/\(\d{2}\) \d \d{4}-\d{4}/.test(formData.telefone)) {
      newErrors.telefone = 'Telefone inválido';
    }
    if (!formData.assunto) newErrors.assunto = 'Assunto é obrigatório';
    if (!formData.mensagem) newErrors.mensagem = 'Mensagem é obrigatória';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSending(true);

    emailjs.send(
      'service_k6zq5oe', //YOUR_SERVICE_ID
      'template_zy5vper', //YOUR_TEMPLATE_ID
      formData,
      'yE6gxj3xQafrDjXj6' //YOUR_PUBLIC_KEY
    )
    .then((response) => {
    //   console.log('SUCCESS!', response.status, response.text);
      setFormData({
        nome: '',
        email: '',
        telefone: '',
        assunto: '',
        mensagem: '',
      });
      alert('Email enviado com sucesso!');
    })
    .catch((err) => {
    //   console.error('Falha...', err);
      alert('Ocorreu um erro ao enviar o email.');
    })
    .finally(() => {
      setIsSending(false);
    });
  };

  return (
    <div className="containerContato" id="contact">
      <div className="leftDivContato">
        <h1>
          {/* Precisa de Ajuda? <br/> */}
          Entre em Contato Conosco!</h1>
        <p>Conte-nos sobre as necessidades do seu negócio e veja como podemos ajudá-lo a ir além.</p>
      </div>
      <div className="rightDivContato">
        <h2>Envie uma Mensagem</h2>
        <form onSubmit={handleSubmit} className="formContato">
          <div className="formGroupContato">
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
              disabled={isSending}
            />
            {errors.nome && <span className="error">{errors.nome}</span>}
          </div>
          <div className="formGroupContato">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isSending}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="formGroupContato">
            <label htmlFor="telefone">Telefone:</label>
            <InputMask
              mask="(99) 9 9999-9999"
              id="telefone"
              name="telefone"
              value={formData.telefone}
              onChange={handleChange}
              required
              disabled={isSending}
            >
              {(inputProps) => <input {...inputProps} type="tel" />}
            </InputMask>
            {errors.telefone && <span className="error">{errors.telefone}</span>}
          </div>
          <div className="formGroupContato">
            <label htmlFor="assunto">Assunto:</label>
            <input
              type="text"
              id="assunto"
              name="assunto"
              value={formData.assunto}
              onChange={handleChange}
              required
              disabled={isSending}
            />
            {errors.assunto && <span className="error">{errors.assunto}</span>}
          </div>
          <div className="formGroupContato">
            <label htmlFor="mensagem">Mensagem:</label>
            <textarea
              id="mensagem"
              name="mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              required
              disabled={isSending}
              maxLength="500"
            ></textarea>
            <small>{formData.mensagem.length}/500 caracteres</small>
            {errors.mensagem && <span className="error">{errors.mensagem}</span>}
          </div>
          <button type="submit" className="submitButton" disabled={isSending}>
            {isSending ? 'Enviando...' : 'Enviar Mensagem'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
