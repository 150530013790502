import React, { useRef, useEffect } from 'react';
import './NossosServicosContainer.css';

const NossosServicosContainer = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    // Quando os metadados (como duração) estiverem carregados
    const handleLoadedMetadata = () => {
      videoElement.currentTime = 0; // Inicia o vídeo no tempo de 10 segundos
    };

    // Controla a execução do vídeo, finalizando-o no tempo de 20 segundos
    const handleTimeUpdate = () => {
      if (videoElement.currentTime >= 15) {
        videoElement.pause(); // Pausa o vídeo ao atingir 20 segundos
        videoElement.currentTime = 0; // Volta ao início definido
        videoElement.play(); // Reinicia
      }
    };

    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <div className="nossos-servicos-container" id='servicos'>
      <h2 className="title-nossos_servicos">Nossos Serviços</h2>
      <div className="content-nossos_servicos">
        <div className="left-div-nossos_servicos">
          <img
            src="/facilite.png"
            alt="Serviço"
            className="image-nossos_servicos"
          />
          <h3 className="nome-nossos_servicos">Criação de APPs sob demanda</h3>
        </div>
        <div className="right-div-nossos_servicos">
          <video
            ref={videoRef}
            muted
            autoPlay
            loop
            controlsList="nodownload" // Remove a opção de download
            className="video-nossos_servicos"
            controls
          >
            <source src="/vidi.mp4" type="video/mp4" />
            Seu navegador não suporta a tag de vídeo.
          </video>
          <h3 className="nome-nossos_servicos">Consultoria e Projetos com <strong>Inteligência Artificial</strong></h3>
        </div>
      </div>
    </div>
  );
};

export default NossosServicosContainer;
